
import {
  defineComponent,
  reactive,
  ref,
  UnwrapRef,
  onMounted,
  createVNode,
} from "vue";
import { useRoute } from "vue-router";
import superadminService from "../../services/superadmin";
import router from "../../router";
import commonService from "../../services/common";
import { ArrowLeftOutlined } from "@ant-design/icons-vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import validate from "../../services/validator";
import { notification, Modal } from "ant-design-vue";
interface Icredit {
  credits: string;
}
export default defineComponent({
  components: {
    ArrowLeftOutlined,
  },
  setup() {
    const route = useRoute();
    const agencyId = ref<string>(route.params.id as string);
    const agencyDetails = ref();
    const pageLoder = ref<boolean>(false);
    const activeI = ref();
    const userLists = ref([]);
    const userColumns = [
      {
        title: "Full Name",
        dataIndex: "full_name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Permission",
        dataIndex: "user_role",
      },
      {
        title: "Status",
        dataIndex: "status",
        slots: { customRender: "status" },
      },
    ];
    const creditFormRef = ref();
    const creditFormRules = validate.addcreditValidation;
    const creditFormModal: UnwrapRef<Icredit> = reactive({
      credits: "",
    });
    const createCreditVisible = ref<boolean>(false);
    const addCreditsTitle = ref();
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const getAgencyDetails = async () => {
      pageLoder.value = true;
      try {
        const res = await superadminService.viewAgencyById(agencyId.value);
        if (res.data.data.success) {
          agencyDetails.value = res.data.data.agency;
          userLists.value = res.data.data.agency.users;
          userLists.value.map((el) => {
            el.full_name = el.first_name + " " + el.last_name;
          });
        }
        pageLoder.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          pageLoder.value = false;
        }
      }
    };
    const backToListing = async () => {
      router.back();
    };
    const addCredit = async() => {
      addCreditsTitle.value = "Add Credits";
      creditFormModal.credits = "";
      createCreditVisible.value = true;
    }
    const cancelCreditModal = async () => {
      createCreditVisible.value = false;
    };
    const addNewCredit = async (data: any) => {
      creditFormRef.value
        .validate()
        .then(() => {
          saveCredit(data);
        })
        .catch((error: ValidateErrorEntity<Icredit>) => {
          console.log("error", error);
        });
    };
    const saveCredit = async (data: any) => {
      pageLoder.value = true;
      try {
        const res = await superadminService.addCredit(agencyId.value, data);
        if (res.data.data.success) {
          await notify("Success", res.data.data.message, "success");
          createCreditVisible.value = false;          
          getAgencyDetails();
        }
        pageLoder.value = false;
      } catch (error: any) { 
        if (error.response) {
          console.log(error.response);
          pageLoder.value = false;
          if (error.response.data.email[0]) {
            await notify("Error", error.response.data.email[0], "error");
          } else {
            await notify("Error", error.response.data.data.message, "error");
          }
        }
      }
    };
    onMounted(async () => {
      getAgencyDetails();
    });
    return {
      agencyId,
      pageLoder,
      backToListing,
      agencyDetails,
      activeI,
      commonService,
      userColumns,
      userLists,
      creditFormRef,
      creditFormRules,
      creditFormModal,
      createCreditVisible,
      cancelCreditModal,
      addCredit,
      addNewCredit,
      addCreditsTitle
    };
  },
});
